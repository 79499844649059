import React from "react"
import { useMemo } from "react"
import { useParams, useNavigate } from "react-router-dom"
import logo from "../images/horizontal-light.svg"
import ProfileMenu from "./ProfileMenu"
import { DatasetSchemas, Datasets, Schemas } from "../lib/store"
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Image,
} from "@chakra-ui/react"
import Link from "./Link"

export default function SchemaSideBar() {
  const [datasetSchemas] = DatasetSchemas.useState()
  const [datasets] = Datasets.useState()
  const [schemas] = Schemas.useState()
  const { datasetId, schemaId } = useParams()
  const navigate = useNavigate()
  const selectedDatasetSchemas = useMemo(() => {
    // If we are viewing all datasets, don't render schemas
    if (!datasetId) return <></>
    // If we have no dataset selected, don't render schemas
    const selected = datasetSchemas[datasetId]
    if (!selected) return <></>
    // Loop over the selected dataset:schemas mapping creating our entries
    return selected.map((id) => {
      let schema = schemas[id]
      return (
        <Box key={id}>
          <Link to={`/datasets/${datasetId}/schemas/${id}/records`}>
            {schema.name}
          </Link>
        </Box>
      )
    })
  }, [schemas, datasetId, schemaId, datasetSchemas])

  return (
    <Container variant="menuStandard">
      <Link to="/" bg="Primary.800">
        <Image
          width="80%"
          margin="auto"
          mt="15px"
          mb="15px"
          src={logo}
          alt="Blind Insight Logo"
        />
      </Link>
      <Accordion variant="dataSets" padding={0}>
        {Object.values(datasets).map((dataset) => (
          <AccordionItem key={dataset.id}>
            <AccordionButton
              onClick={() => navigate(`/datasets/${dataset.id}`)}
            >
              {dataset.name}
            </AccordionButton>
            <AccordionPanel>
              {dataset.id === datasetId ? selectedDatasetSchemas : null}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <Container margin="auto" />
      <ProfileMenu />
    </Container>
  )
}
