import React, { useEffect, useMemo, useState } from "react"

import {
  useParams,
  useSearchParams,
  useRouteLoaderData,
  Form,
} from "react-router-dom"

import {
  Box,
  Flex,
  Button,
  Input,
  Alert,
  Text,
  HStack,
  Select,
} from "@chakra-ui/react"
import { DataTable } from "./DataTable"
import { HashedSchemas, Schemas, CurrentFilters } from "../lib/store"
import { Api } from "../lib/api"
import { isEmpty } from "../lib/util"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { FaFilter } from "react-icons/fa"
import { FaX } from "react-icons/fa6"
import IconLock from "@/icons/IconLock"

export default function SchemaRecordsList() {
  const [error, setError] = React.useState(null)
  const user = useRouteLoaderData("auth")
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = parseInt(searchParams.get("limit") || 25)
  const offset = parseInt(searchParams.get("offset") || 0)
  const { schemaId } = useParams()
  const [schemas] = Schemas.useState()
  const [currentFilters] = CurrentFilters.useState()
  const api = new Api()

  // List of current records in view
  const [records, setRecords] = useState([])
  // Mapping of hashed schemas
  const [hashedSchemas] = HashedSchemas.useState()
  // Find the selected schema
  const schema = useMemo(() => schemas[schemaId], [schemas, schemaId])
  // Get the hashed (decrypted) schema
  const hashedSchema = useMemo(
    () => hashedSchemas[schemaId],
    [hashedSchemas, schemaId],
  )
  console.log("HashedSchema = ", hashedSchema)

  // Decryption toggle
  const [encrypted, setEncrypted] = useState(true)
  // Can decrypt schema?
  const [canDecryptSchema, setCanDecryptSchema] = useState(true)

  const isDataOwner = useMemo(() => {
    return !user?.email?.startsWith("data_requester")
  }, [user])

  // Can decrypt records?
  const [canDecryptRecords, setCanDecryptRecords] = useState(() => {
    // TODO(jathan): This is a temporary hack to disallow decryption for data_requester for now.
    if (!isDataOwner) return false
    // TODO(shakefu): Get decrypt permission state from the API when we
    // support that metadata call
    // Default to allowing decryption, once
    return true
  })
  // Records count
  const count = useMemo(() => {
    if (!records?.count) {
      return -1
    }
    return records.count
  }, [records])
  console.log("count=", count)
  // Records to decrypt
  const [missingRecords, setMissingRecords] = useState({})
  // Decrypted records
  const [decryptedRecords, setDecryptedRecords] = useState({})
  // Records pending decryption
  const [pendingRecords, setPendingRecords] = useState({})
  // Schema pending decryption
  const [pendingSchema, setPendingSchema] = useState(false)
  const properties = useMemo(() => schema?.schema?.properties || {}, [schema])

  // Record type used for table column display.
  const RecordType = useMemo(() => {
    if (!hashedSchema) return {}
    let x = {}
    const typeMap = { string: String, number: Number }

    // If we're encrypted, use the hashed schema.
    if (encrypted) {
      for (const [key, value] of Object.entries(hashedSchema)) {
        x[key] = typeMap[value.type]
      }
    } else {
      // Otherwise, use the decrypted schema.
      for (const [key, value] of Object.entries(schema.schema.properties)) {
        x[key] = typeMap[value.type]
      }
    }
    return x
  }, [hashedSchema, schema, encrypted])
  console.log("RecordType = ", RecordType)

  const filterMenuItems = useMemo(() => {
    const entries = Object.entries(properties)
    if (!entries.length) return []
    return entries.map(([name]) => (
      <option key={name} value={name}>
        {name}
      </option>
    ))
  }, [properties])

  const recordMap = useMemo(() => {
    if (pendingSchema) {
      console.debug("recordMap waiting for schema...")
      return
    }
    console.debug("Creating recordMap...")
    let inverted = {}
    for (const record of records) {
      inverted[record.id] = record.data
    }
    if (encrypted) {
      console.debug("Returning encrypted recordMap", inverted)
      return inverted
    }

    let changed = false
    let missing = {}
    for (const id in missingRecords) {
      missing[id] = missingRecords[id]
    }
    for (const record of records) {
      if (!decryptedRecords[record.id]) {
        if (pendingRecords[record.id]) continue
        if (!missing[record.id]) {
          changed = true
          missing[record.id] = record
        }
      }
    }
    if (changed) {
      console.debug("Need to decrypt missing records", missing)
      setMissingRecords(missing)
    }

    console.debug("Returning decryptedRecords", decryptedRecords)
    return decryptedRecords
  }, [
    records,
    decryptedRecords,
    missingRecords,
    pendingRecords,
    pendingSchema,
    encrypted,
  ])

  // This effect will attempt to decrypt the schema if it's not already decrypted.
  // If it fails, it won't retry.
  React.useEffect(() => {
    if (!schema) return
    // If we're not decrypting, get out early
    if (!canDecryptSchema) return
    if (pendingSchema) return
    if (hashedSchemas[schema.id]) return
    setPendingSchema(true)
    const api = new Api()
    console.debug("Decrypting schema for SchemaRecordsList", schema.id)
    api
      .schemasDecrypt(schema)
      .then(() => {
        setPendingSchema(false)
      })
      .catch(() => {
        setPendingSchema(false)
        setCanDecryptSchema(false)
      })
  }, [
    hashedSchemas,
    schema,
    pendingSchema,
    setPendingSchema,
    canDecryptSchema,
    setCanDecryptSchema,
  ])

  const [hasProxy, setHasProxy] = useState(true)

  // This effect will attempt to decrypt the records if "encrypted" is false.
  // If it fails, it should revert to displaying the encrypted records.
  React.useEffect(() => {
    // If we're not decrypting, get out early
    if (encrypted) return

    // If we aren't allowed to decrypt, get out early
    if (!canDecryptRecords) return

    // Calculate actual missing records vs what is pending in an API query
    let missing = []
    if (Object.keys(missingRecords).length > 0) {
      console.debug("Merging missing and pending records", {
        missingRecords,
        pendingRecords,
      })
      let pending = {}
      for (const id in missingRecords) {
        if (!pendingRecords[id]) {
          missing.push(missingRecords[id])
          pending[id] = true
        }
      }
      for (const id in pendingRecords) {
        pending[id] = true
      }

      // If we didn't find any changes, get out
      if (missing.length === 0) return

      // Clear missing records that were set
      console.debug("Clearing missing records", missing)
      setMissingRecords({})

      // Update the pending record state
      console.debug("Updating pending records")
      setPendingRecords(pending)
    }

    console.debug("Missing records", missing)

    // If we're already pending decryption, get out early
    if (missing.length === 0) return

    const api = new Api()
    api
      .recordsDecrypt(missing)
      .then((data) => {
        console.debug("Raw decrypted data", data)
        let newDecrypted = {}
        for (const record of data) {
          newDecrypted[record.id] = record.data
        }
        for (const id in decryptedRecords) {
          newDecrypted[id] = decryptedRecords[id]
        }
        console.debug("Updating decrypted records", decryptedRecords)
        setDecryptedRecords(newDecrypted)
      })
      .catch((error) => {
        console.log("Error decrypting records", error)
        setMissingRecords({})
        if (error?.status === 404) {
          console.log("Decrypt records not found")
          setError("Decrypted records not found, please refresh the page.")
          return
        }
        setEncrypted(true)
        if (error?.status === 403) {
          console.log("Decrypt permission denied")
          setError("You do not have permission to decrypt these records.")
          return setCanDecryptRecords(false)
        }
        // TODO(shakefu): This 405 is a wonky status code.
        if (error?.status === 405) {
          console.log("Decrypt not available on API")
          //commented this out so there isn't a duplicate message displayed in the UI (see line 375-ish)
          //setError(<Fragment>Decryption unavailable, please <a href='https://docs.blindinsight.io/getting-started/using-the-blind-proxy/#using-the-blind-proxy' target='new'>run the Blind Proxy</a> to decrypt.</Fragment>)
          return setHasProxy(false)
        }
      })
  }, [
    encrypted,
    setEncrypted,
    canDecryptRecords,
    setCanDecryptRecords,
    missingRecords,
    setMissingRecords,
    decryptedRecords,
    setDecryptedRecords,
    pendingRecords,
    setPendingRecords,
  ])

  // Render nothing if we don't have a user
  if (!user) return null
  // Render a not found if we couldn't find a schema in state
  if (!schema) return <Alert severity="error">Schema not found</Alert>

  const toggleEncryption = () => {
    if (!canDecryptRecords && encrypted) {
      console.debug("Can't toggle encryption, can't decrypt")
      return
    }
    console.debug("Toggling encryption")
    setEncrypted(!encrypted)
  }

  const fieldNames = useMemo(() => {
    if (!recordMap) return []

    if (!hashedSchema || isEmpty(hashedSchema)) {
      // Flatten the recordMap to get the field names and remove duplicates as a set.
      const encryptedKeys = Object.values(recordMap).map((record) =>
        Object.keys(record),
      )
      const flattenedKeys = encryptedKeys.flat()
      const uniqueKeys = new Set(flattenedKeys)
      // console.log("fieldNames flattenedKeys=", flattenedKeys)
      return Array.from(uniqueKeys)
    } else {
      return Object.keys(hashedSchema || {}).map((key) => {
        if (encrypted) return key
        return hashedSchema[key]?.name
      })
    }
  }, [recordMap, hashedSchema, encrypted])

  // missing here is the idField in the row - JP just can't
  const columns = useMemo(() => {
    return fieldNames.map((name) => ({
      accessorKey: name,
      header: name,
      field: name,
      type: properties[name]?.type,
      size: 150,
    }))
  }, [fieldNames, properties])
  console.log("columns=", columns)

  const data = useMemo(() => {
    return records.map((record) => {
      if (encrypted) {
        return { id: record.id, ...record.data }
      }
      return { id: record.id, ...decryptedRecords[record.id] }
    })
  }, [records, decryptedRecords, encrypted])
  console.log("data=", data)

  const [pagination, setPagination] = useState({
    pageIndex: Math.ceil(offset / limit), //initial page index (aka offset)
    pageSize: limit, //default page size (aka limit)
  })

  // This effect will update the search params when the pagination changes.
  useEffect(() => {
    // console.log("handlePaginate", "pageModel", pageModel)
    const newPage = pagination.pageIndex
    const newLimit = pagination.pageSize
    let params = {
      offset: Math.ceil(newPage * newLimit),
      limit: newLimit,
    }
    console.log("handlePaginate params=", params)
    setSearchParams(params)
  }, [pagination])

  const [filters, setFilters] = React.useState([
    { label: "", operator: "", value: "", value2: "" },
  ])
  const handleAddFilter = () => {
    setFilters([...filters, { label: "", operator: "", value: "", value2: "" }])
  }

  const handleRemoveFilter = () => {
    setFilters([...filters.slice(0, -1)])
  }

  const handleInputChange = (e, index) => {
    const values = [...filters]
    if (e.target.name === "label") {
      values[index].label = e.target.value
    } else if (e.target.name === "operator") {
      values[index].operator = e.target.value
    } else if (e.target.name === "value") {
      values[index].value = e.target.value
    } else {
      values[index].value2 = e.target.value
    }
    setFilters(values)
  }

  const handleSearch = () => {
    let newFilters = []
    filters.map(function (filter) {
      let newValue = {}
      let expression = {}
      if (filter.value2) {
        newValue = filter.value + filter.operator + filter.value2
      } else if (filter.operator != "=") {
        newValue = filter.operator + filter.value
      } else {
        newValue = filter.value
      }
      if (filter.label && newValue) {
        expression.label = filter.label
        expression.value = newValue
        newFilters = [...newFilters, expression]
      }
    })
    CurrentFilters.setValue(newFilters)
  }

  React.useEffect(() => {
    // Refresh the records when the filters change.
    const updateRecords = async () => {
      const resp = await api.recordsSearch(
        schemaId,
        currentFilters,
        limit,
        offset,
      )
      setRecords(resp)
    }
    updateRecords()
  }, [currentFilters, schemaId, limit, offset])

  // Clear the filters and reset the search params
  const clearFilters = () => {
    setFilters([{ label: "", operator: "=", value: "", value2: "" }])
    CurrentFilters.setValue([])
    setSearchParams({})
  }

  const EncryptButton = () => {
    return (
      <>
        <IconLock
          locked="Danger.100"
          unlocked="Success.100"
          encrypted={encrypted}
        />
        <Button
          variant="secondary"
          size="sm"
          onClick={toggleEncryption}
          isDisabled={canDecryptRecords === false}
          title={
            canDecryptRecords
              ? "Toggle encryption"
              : "You must be an authorized data owner and decrypt locally using the proxy."
          }
        >
          {encrypted ? "Decrypt" : "Encrypt"}
        </Button>
      </>
    )
  }

  // TODO(jathan): Trigger decryptRecords on load to get proxy overlay to show. Remove this when we have proxy detect endpoint.
  React.useEffect(() => {
    toggleEncryption()
  }, [])

  return (
    <Flex direction="column" width="100%" gap="4" mt="5">
      <Box>{schema.description}</Box>
      <Box>
        {hasProxy === false && (
          <Box>
            <Alert width="fit-content" severity="info" variant="standard">
              <Text>
                Please{" "}
                <a
                  href="https://docs.blindinsight.io/getting-started/using-the-blind-proxy/#using-the-blind-proxy"
                  target="new"
                >
                  download and run the Blind Proxy
                </a>{" "}
                to search and decrypt locally. Feel free to get in touch if you
                need assistance.
              </Text>
            </Alert>
          </Box>
        )}
        <HStack spacing="2" verticalAlign="middle" alignItems="center">
          <Form id="filter-set">
            <Flex
              direction="row"
              gap="2"
              verticalAlign="middle"
              alignItems="center"
            >
              <Flex>
                {filters.map((filter, index) => (
                  <HStack
                    key={index}
                    bg="white"
                    verticalAlign="middle"
                    alignItems="center"
                    borderRadius="4"
                    gap="2"
                    maxheight="2em"
                    p="1"
                  >
                    <Select
                      name="label"
                      label="Column"
                      onChange={(e) => handleInputChange(e, index)}
                      value={filter.label ?? ""}
                      title="Select a column to filter by"
                      size="sm"
                    >
                      <option>Column</option>
                      {filterMenuItems}
                    </Select>
                    {filter.label &&
                      properties[filter.label]?.type === "number" && (
                        <HStack verticalAlign="middle">
                          <Box minW="3em">
                            <Select
                              verticalAlign="middle"
                              variant="filterOperand"
                              name="operator"
                              onChange={(e) => handleInputChange(e, index)}
                              defaultValue="="
                              width="4em"
                            >
                              <option value="=" title="equals">
                                =
                              </option>
                              <option value=">" title="greater than">
                                &gt;
                              </option>
                              <option value="<" title="less than">
                                &lt;
                              </option>
                              <option
                                value=">="
                                title="greater than or equal to"
                              >
                                &ge;
                              </option>
                              <option value=">=" title="less than or equal to">
                                &le;
                              </option>
                              <option value="~" title="between">
                                &lt; .. &gt;
                              </option>
                            </Select>
                          </Box>
                          <Input
                            maxW="4em"
                            minW="4em"
                            variant="filter"
                            type="number"
                            name="value"
                            value={filter.searchValue}
                            onChange={(e) => handleInputChange(e, index)}
                            placeholder="Value"
                            title="Enter a value to filter by"
                            size="small"
                          />
                        </HStack>
                      )}
                    {filter.operator && filter.operator === "~" && (
                      <Input
                        ml="0.25em"
                        maxW="4em"
                        minW="4em"
                        variant="filter"
                        type="number"
                        name="value2"
                        value={filter.searchValue2}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Value"
                        title="Enter a max value to filter by"
                        size="small"
                      />
                    )}
                    {filter.label &&
                      properties[filter.label]?.type === "string" && (
                        <>
                          <Text>=</Text>
                          <Input
                            width="8em"
                            variant="filter"
                            value={filter.searchValue}
                            onChange={(e) => handleInputChange(e, index)}
                            name="value"
                            placeholder="Value"
                            size="small"
                            title="Value to filter by"
                          />
                        </>
                      )}
                  </HStack>
                ))}
              </Flex>
              {filters.length > 1 && (
                <Button
                  variant="grayRounded"
                  size="xs"
                  onClick={handleRemoveFilter}
                >
                  <MinusIcon />
                </Button>
              )}
              {filters.length < filterMenuItems.length &&
                filters.length > 0 && (
                  <Button
                    variant="grayRounded"
                    size="xs"
                    onClick={handleAddFilter}
                  >
                    <AddIcon />
                  </Button>
                )}
              <Button
                variant="primary"
                type="submit"
                onClick={handleSearch}
                rightIcon={<FaFilter />}
                title="Filter data"
                size="sm"
              >
                Filter
              </Button>

              <Button
                as="Link"
                onClick={clearFilters}
                buttonStyle="inline"
                rightIcon={<FaX />}
                title="Clear filters"
                size="sm"
              >
                Clear filters
              </Button>
            </Flex>
          </Form>
        </HStack>
      </Box>
      <Box>
        {error && (
          <Box>
            {" "}
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <DataTable
          EncryptButton={EncryptButton}
          data={data}
          columns={columns}
          rowCount={count}
          pagination={pagination}
          onPaginationChange={setPagination}
        />
      </Box>
    </Flex>
  )
}
