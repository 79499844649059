import React, { useState } from "react"
import { Form } from "react-router-dom"
import { useRouteLoaderData } from "react-router-dom"
import { onChange } from "../lib/util"
import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react"
import IconEdit from "@/icons/IconEdit"
import IconSave from "@/icons/IconSave"
export default function ProfileView() {
  const user = useRouteLoaderData("auth")
  let [editMode, setEditMode] = useState("")

  if (!user) return null
  return (
    <Box display="flex" flexDirection="column" mt="8">
      <Heading fontSize="md">Login & Security</Heading>
      {(editMode === "email" && (
        <Form>
          <InputGroup variant="standardAddon">
            <InputLeftAddon>Email Address: </InputLeftAddon>
            <Input
              name="slug"
              placeholder={user.email}
              value={user.email}
              onChange={onChange(user.email)}
              isRequired
            />
            <InputRightAddon>
              <IconSave
                color="Primary.500"
                as="button"
                type="submit"
                onClick={() => {
                  setEditMode("")
                }}
              />
            </InputRightAddon>
          </InputGroup>
        </Form>
      )) || (
        <Box>
          Email address: {user.email}
          <IconEdit
            ml="1em"
            color="Primary.500"
            as="button"
            onClick={() => {
              setEditMode("email")
            }}
          />
        </Box>
      )}
      {(editMode === "password" && (
        <>
          <Form>
            <InputGroup variant="standardAddon">
              <InputLeftAddon>Old password: </InputLeftAddon>
              <Input
                name="old-password"
                placeholder="old password"
                size="small"
                type="password"
                helperText="Enter your old password"
                required
                label="Old password"
              />
            </InputGroup>
            <InputGroup mt="0.5em" variant="standardAddon">
              <InputLeftAddon>New password: </InputLeftAddon>
              <Input
                name="password"
                placeholder="new password"
                size="small"
                type="password"
                helperText="Enter your new password"
                required
                label="New password"
              />
              <InputRightAddon>
                <IconSave
                  as="Button"
                  type="submit"
                  onClick={() => {
                    setEditMode("")
                  }}
                />
              </InputRightAddon>
            </InputGroup>
          </Form>
        </>
      )) || (
        <Box>
          Password: ********
          <IconEdit
            ml="1em"
            as="button"
            color="Primary.500"
            onClick={() => {
              setEditMode("password")
            }}
          />
        </Box>
      )}
      <Heading fontSize="md">Personal info</Heading>
      {(editMode === "name" && (
        <>
          <Form>
            <InputGroup variant="standardAddon">
              <InputLeftAddon>First name: </InputLeftAddon>
              <Input
                variant="standardAddon"
                placeholder={user.first_name}
                isRequired
              />
              <InputRightAddon>
                <IconSave
                  color="Primary.500"
                  as="Button"
                  type="submit"
                  //TODO: reintroduce this feature once name change is implemented
                  // onClick={() => {
                  //   setEditMode("")
                  // }}
                />
              </InputRightAddon>
            </InputGroup>
          </Form>
        </>
      )) || (
        <Box>
          Full name: {user.first_name} {user.last_name}
          <IconEdit
            ml="1em"
            as="button"
            color="Primary.500"
            onClick={() => {
              setEditMode("name")
            }}
          />
        </Box>
      )}
    </Box>
  )
}
