import React from "react"
import {
  Outlet,
  useLoaderData,
  useRouteError,
  json,
  isRouteErrorResponse,
  redirect,
} from "react-router-dom"
import { Box } from "@mui/material"
import { CurrentUser } from "../lib/store"
import { UserIsLoggedIn } from "../lib/user"
import SchemaSideBar from "./Sidebar"
import LoginView from "../views/LoginView"

export default function AuthRequired() {
  const auth = useLoaderData()
  return <Outlet value={auth} />
}

AuthRequired.loader = async () => {
  let loggedIn

  try {
    loggedIn = await UserIsLoggedIn()
  } catch (error) {
    console.log(">>> Error in AuthRequired.loader")
    const badStatuses = [401, 403]
    if (badStatuses.includes(error.status)) {
      console.log(">>> Redirecting to login")
      return redirect("/login")
    }
    throw error
  }

  if (!loggedIn) {
    console.log("No auth")
    throw json({ error: "No auth" }, { status: 403 })
  }

  if (loggedIn) {
    console.log("Is logged in")
    return CurrentUser.getValue()
  }

  console.log("Bad auth state")
  throw json({ error: "Bad auth state" }, { status: 403 })
}

export function AuthRequiredError() {
  const error = useRouteError()

  // console.debug(error)
  if (!isRouteErrorResponse(error)) {
    // const strErr = JSON.stringify(error, null, 2)
    return (
      <Box display="flex" height="100vh">
        <SchemaSideBar />
        <div className="main">
          <Box>
            <Box>
              <h1>Something is horribly wrong!</h1>
              <pre>{error.stack}</pre>
            </Box>
          </Box>
        </div>
      </Box>
    )
  }

  switch (error.status) {
    case 400:
      // No password given
      return <LoginView />
    case 422:
      // TODO(jathan): Display an actual proxy error screen.
      // Proxy error. Most likely failed to pass through auth.
      return <LoginView />
    default:
      return ErrorPage(error)
  }
}

function ErrorPage(error) {
  return (
    <Box display="flex" height="100vh">
      <SchemaSideBar />
      <div className="main">
        <Box>
          <Box>
            <h1>Something is horribly wrong!</h1>
            <pre>{error.stack}</pre>
          </Box>
        </Box>
      </div>
    </Box>
  )
}
