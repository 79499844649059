import React from "react"
import { useRouteLoaderData } from "react-router-dom"
import DatasetCard from "./DatasetCard"
import { Datasets } from "../lib/store"
import {
  Box,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react"

export default function DatasetsList() {
  const user = useRouteLoaderData("auth")
  const [datasets] = Datasets.useState()

  if (!user) return null

  return (
    <Box padding="1em">
      <Box
        mt="1em"
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        gap="2em"
      >
        <DatasetCard dataset="default" />
        {Object.keys(datasets).length < 1 && (
          <Box>
            <Heading variant="standardSub">
              Welcome to the Blind Insight Beta!
            </Heading>
            <Text>
              Start exploring the UI or check out the links below for the full
              developer experience.
            </Text>
            <Heading variant="standardSub">Developer Quickstart</Heading>
            <OrderedList>
              <ListItem>
                <Link
                  href="https://docs.blindinsight.io/download/"
                  target="new"
                >
                  Download the Blind Proxy
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://github.com/blind-insight/demo-datasets/releases"
                  target="new"
                >
                  Download a sample dataset, or use your own
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://docs.blindinsight.io/getting-started/"
                  target="new"
                >
                  Check out the Getting Started Guide
                </Link>
              </ListItem>
            </OrderedList>
          </Box>
        )}
        {Object.values(datasets).map((dataset, index) => (
          <Box key={index}>
            <DatasetCard dataset={dataset} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
