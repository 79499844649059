import React from "react"
import { useRouteLoaderData } from "react-router-dom"
import { CurrentOrg } from "../lib/store"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react"
import { SettingsIcon, QuestionIcon } from "@chakra-ui/icons"
import Link from "./Link"

export default function ProfileMenu() {
  const user = useRouteLoaderData("auth")
  const [currentOrg] = CurrentOrg.useState()

  if (!user) return null

  return (
    <Accordion variant="standard" allowToggle="true">
      <AccordionItem>
        <AccordionButton>
          <Text color="white">
            Account <SettingsIcon />
          </Text>
        </AccordionButton>
        <AccordionPanel>
          <Stack direction="column">
            <FormControl>
              <FormLabel>Organization:</FormLabel>
              <Select id="org-select" label="org-select" defaultValue={""}>
                <option value="">{currentOrg.name}</option>
              </Select>
            </FormControl>
            <Link to="/account">
              <Text color="white">Account Settings</Text>
            </Link>
            <Link
              to="https://docs.blindinsight.io"
              target="_blank"
              rel="noreferrer"
            >
              API Docs
            </Link>
            <Link
              onClick={() => {
                window.Intercom("show")
              }}
            >
              <Text color="white">
                Help <QuestionIcon />
              </Text>
            </Link>
            <Link to="/logout">Logout</Link>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
