import React from "react"
import { useParams, Outlet, useLocation } from "react-router-dom"

import { Box, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react"
import Link from "./Link"

export default function SchemaTabs() {
  const { datasetId, schemaId } = useParams()
  const location = useLocation()

  const getTabIndex = () => {
    if (location.pathname.includes("/records")) {
      return 0
    } else {
      return 1
    }
  }

  return (
    <Box>
      <Tabs variant="standard" width="100%" index={getTabIndex()}>
        <TabList>
          <Tab>
            <Link
              to={`/datasets/${datasetId}/schemas/${schemaId}/records`}
              style={{ textDecoration: "none" }}
            >
              Records
            </Link>
          </Tab>
          <Tab>
            <Link
              to={`/datasets/${datasetId}/schemas/${schemaId}`}
              style={{ textDecoration: "none" }}
            >
              Schema
            </Link>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Outlet />
          </TabPanel>
          <TabPanel>
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
