import React from "react"
import { Form, useNavigate } from "react-router-dom"
import {
  Alert,
  Button,
  Heading,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react"
import Logo from "../images/horizontal-light.svg"
import { UserLogin } from "../lib/user"
import { onChange } from "../lib/util"
import GradientBox from "./GradientBox"
import Link from "./Link"
import { GoogleLogin } from "@react-oauth/google"
import { Api } from "../lib/api"

export default function Login() {
  const api = new Api()
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const navigate = useNavigate()

  const login = async (event) => {
    event.preventDefault()
    console.log("Logging user in...")
    let success
    try {
      success = await UserLogin(username, password)
    } catch (e) {
      success = false
    }

    if (!success) {
      setPassword("")
      setSuccess(null)
      setError("Login failed")
    } else {
      setError(null)
      setUsername("")
      setPassword("")
      console.log("Login success")
      navigate("/")
    }
  }

  const redirectToRegister = () => {
    navigate("/register")
  }

  const responseMessage = async (response) => {
    console.log(response)
    await api.googleLogin(response.credential, response.clientId)
  }

  const errorMessage = (error) => {
    console.log(error)
    setError(error)
  }

  return (
    <GradientBox>
      <Image src={Logo} alt={"Blind Insight Logo"} width="20vw" />
      <VStack minWidth="100%" justifyContent="space-between" alignItems="left">
        <Form onSubmit={login}>
          <Heading variant="standard">Sign In</Heading>
          <Text variant="subtext">Sign in to your account</Text>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <VStack
            mt="2em"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            minWidth="100%"
            spacing="0"
          >
            <Input
              variant="account"
              placeholder="Email"
              autoComplete="username"
              label="Email address"
              value={username}
              onChange={onChange(setUsername)}
              type="email"
              name="email"
              isRequired
            />
            <Text variant="subtext">{error || ""}</Text>
            <Input
              variant="account"
              placeholder="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={onChange(setPassword)}
              name="password"
              status={error && "error"}
              mt="1.5vh"
              isRequired
            />
            <Text variant="subtext" mt="0.25em" ml="auto">
              {error || (
                <>
                  {" "}
                  <Link
                    textDecoration={"underline"}
                    to="/accounts/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </>
              )}
            </Text>
            <VStack
              mt="2em"
              minWidth="100%"
              alignContent="center"
              spacing="0.25em"
            >
              <Button variant="account" type="submit" id="submit">
                Sign In
              </Button>
              <Text variant="subtext"> &mdash; OR &mdash; </Text>
              <GoogleLogin
                onSuccess={responseMessage}
                onError={errorMessage}
                shape="pill"
                text="signin_with"
                context="signin"
                ux_mode="popup"
              />
            </VStack>
          </VStack>
        </Form>
      </VStack>
      <Text variant="subtext" mt="1em">
        Already have an account?{" "}
        <Link
          onClick={redirectToRegister}
          textDecoration={"underline"}
          color="white"
        >
          Register here
        </Link>
      </Text>
    </GradientBox>
  )
}
