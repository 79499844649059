import React from "react"

import { Alert } from "@chakra-ui/react"

import ResetPassword from "@/components/ResetPassword"
import Footer from "@/components/Footer"
import Message from "@/components/Message"
import AccountLayout from "@/components/AccountLayout"

export default function ResetPasswordView() {
  const [resetSuccess, setResetSuccess] = React.useState(null)
  const [resetError, setResetError] = React.useState(null)

  return (
    <AccountLayout>
      <ResetPassword
        setResetSuccess={setResetSuccess}
        setResetError={setResetError}
      />
      <Message>
        {`Reset your Blind Insight password`}
        {`Please select a new password.`}
        {resetSuccess && (
          <Alert variant="standard" severity="success">
            {resetSuccess}
          </Alert>
        )}
        {resetError && (
          <Alert variant="standard" severity="error">
            {resetError}
          </Alert>
        )}
      </Message>
      <Footer />
    </AccountLayout>
  )
}
