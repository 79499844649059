import React from "react"
import ForgotPassword from "@/components/ForgotPassword"
import AccountLayout from "@/components/AccountLayout"
import Footer from "@/components/Footer"
import Message from "@/components/Message"

import { Alert, Text } from "@chakra-ui/react"

export default function ForgotPasswordView() {
  const [linkSent, setLinkSent] = React.useState(false)
  const [toEmail, setToEmail] = React.useState("")

  return (
    <AccountLayout>
      <ForgotPassword setLinkSent={setLinkSent} setToEmail={setToEmail} />
      <Message>
        <Text fontSize="2em">Forgot your Blind Insight password?</Text>
        <Text mt="1em" fontSize="1.5em">
          Enter your email and we will send you a link.
        </Text>
        {linkSent && (
          <Alert severity="success" flexDirection="column" alignItems="start">
            <Text fontSize="1.5em">
              Password reset link sent to <b>{toEmail}</b>
            </Text>
            <Text fontSize="1.5em">
              Please check your email and click the link!
            </Text>
          </Alert>
        )}
      </Message>
      <Footer />
    </AccountLayout>
  )
}
