import React from "react"
import { Form, useNavigate } from "react-router-dom"
import {
  Alert,
  Button,
  Checkbox,
  Heading,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
} from "@chakra-ui/react"
import Logo from "../images/horizontal-light.svg"
import { UserRegister } from "../lib/user"
import { onChange } from "../lib/util"
import TOU from "../views/TOU"
import { GoogleLogin } from "@react-oauth/google"
import { Api } from "../lib/api"

// Styles
import "./Login.css"
import GradientBox from "./GradientBox"

export default function Register() {
  const api = new Api()
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const navigate = useNavigate()

  const handleRegister = async (event) => {
    event.preventDefault()

    const { email, password, fullName, organizationName } =
      event.target.elements
    let success
    try {
      success = await UserRegister(
        email.value,
        password.value,
        fullName.value,
        organizationName.value,
      )
    } catch (e) {
      success = false
    }

    if (!success) {
      setError("Registration failed")
      setSuccess(null)
    } else {
      console.log("Registration success")
      setError(null)
      setSuccess(
        "Registration successful. Please check your email for an activation link.",
      )
      navigate("/accounts/register-confirm")
    }
  }
  const [open, setOpen] = React.useState(false)
  const [TOSState, setTOSState] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setTOSState(!TOSState)
  }

  const handleCheckboxChange = (event) => {
    setTOSState(event.target.checked)
  }

  const handleConfirmedClose = () => {
    setOpen(false)
    setTOSState(true)
  }

  const redirectToLogin = () => {
    navigate("/login")
  }

  const responseMessage = async (response) => {
    console.log(response)
    await api.googleLogin(response.credential, response.clientId)
  }

  const errorMessage = (error) => {
    console.log(error)
    setError(error)
  }

  return (
    <GradientBox>
      <Image width="20vw" src={Logo} alt="Blind Insight Logo" />
      <VStack minWidth="100%" justifyContent="space-between" alignItems="left">
        <Form onSubmit={handleRegister}>
          <Heading variant="standard">Sign Up for free</Heading>
          <Text variant="subtext">
            {"Let's get started with your 30 day free trial"}
          </Text>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <VStack
            mt="2vh"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            minWidth="100%"
            spacing="1vh"
          >
            <Input
              variant="account"
              placeholder="Email"
              autoComplete="username"
              className="full-width"
              name="email"
              type="email"
              onChange={onChange(setUsername)}
              value={username}
              status={error && "error"}
              isRequired
            />
            <Text variant="subtext">{error || ""}</Text>
            <Input
              variant="account"
              placeholder="Password"
              autoComplete="password"
              name="password"
              type="password"
              onChange={onChange(setPassword)}
              status={error && "error"}
              value={password}
              isRequired
            />
            <Text variant="subtext">{error || " "}</Text>
            <Input
              variant="account"
              placeholder="Organization Name"
              name="organizationName"
              status={error && "error"}
              isRequired
            />
            <Text variant="subtext">{error || " "}</Text>
            <Input
              variant="account"
              placeholder="Full Name"
              name="fullName"
              status={error && "error"}
              isRequired
            />
            <Text variant="subtext">{error || " "}</Text>
            <Checkbox
              name="tos"
              isRequired
              isChecked={TOSState}
              onChange={handleCheckboxChange}
              variant="standard"
            >
              <Text variant="subtext">
                I agree to the{" "}
                <Link onClick={handleOpen} textDecoration={"underline"}>
                  Terms of Use.
                </Link>
              </Text>
            </Checkbox>
            <Checkbox name="robot" variant="standard" isRequired>
              <Text variant="subtext"> I am not a robot.</Text>
            </Checkbox>
          </VStack>
          <VStack spacing="0.5vh" mt="2vh">
            <Button variant="account" type="submit" id="submit">
              Create Account
            </Button>
            <Text variant="subtext"> &mdash; OR &mdash; </Text>
            <GoogleLogin
              onSuccess={responseMessage}
              onError={errorMessage}
              shape="pill"
              text="signup_with"
              context="signup"
              ux_mode="popup"
            />
          </VStack>
        </Form>
      </VStack>
      <Modal isOpen={open} onClose={handleClose} variant="standard">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of use</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            <TOU />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={handleConfirmedClose}>
              I agree to the Terms of Use
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Text mt="1em" variant="subtext">
        Already a user?{" "}
        <Link onClick={redirectToLogin} textDecoration={"underline"}>
          Sign In
        </Link>
      </Text>
    </GradientBox>
  )
}
