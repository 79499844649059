import React from "react"
import { Box } from "@chakra-ui/react"
import Link from "./Link"

export default function Footer() {
  return (
    <Box position="fixed" bottom="0" right="20vw" height="2em" color="black">
      &copy; 2024, <Link href="https://blindinsight.com">Blind Insight</Link> |{" "}
      <Link href="https://docs.blindinsight.io">View docs</Link>
    </Box>
  )
}
