import React from "react"
import AccountLayout from "@/components/AccountLayout"
import Message from "@/components/Message"
import Login from "../components/Login"
import { Heading, Text } from "@chakra-ui/react"
import { getEnvironment } from "../lib/util"
import Link from "../components/Link"
import Footer from "../components/Footer"

export default function LoginView() {
  return (
    <AccountLayout>
      <Login />
      <Message>
        <Heading variant="standardBlack">
          Welcome to Blind Insight {getEnvironment()}
        </Heading>
        <Text>Accounts are free for 60 days - no credit card required.</Text>
        <br />
        <Text>
          Free accounts are in a shared environment. Paid accounts receive their
          own private environment along with white-glove support.
        </Text>
        <br />
        <Text>
          If you have any questions you can always reach out through the
          Intercom messenger at the bottom right of your screen, or see our{" "}
          <Link
            color="Primary.500"
            href="https://docs.blindinsight.io/getting-started/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Getting Started Guide.
          </Link>
        </Text>
      </Message>
      <Footer />
    </AccountLayout>
  )
}
