import React from "react"
import AccountLayout from "@/components/AccountLayout"
import Message from "@/components/Message"
import Register from "../components/Register"
import Footer from "../components/Footer"
import { Text, VStack, HStack } from "@chakra-ui/react"
import { Image } from "@chakra-ui/react"
import EmmeLogo from "../images/emmeLogo.png"
import CallBoxLogo from "../images/callboxLogo.png"
import LaconicLogo from "../images/laconicLogo.png"
import GdahaLogo from "../images/gdahaLogo.png"

export default function RegisterView() {
  return (
    <AccountLayout>
      <Register />
      <Message>
        <VStack minWidth="100%">
          <VStack minWidth="100%" alignItems="flex-start">
            <Text variant="register">
              “Partnering with Blind Insight allowed us to accelerate our
              roadmap by a full year, moving us into new product areas and
              unlocking revenue faster than we could on our own.”
            </Text>
          </VStack>
          <VStack minWidth="100%" alignItems="flex-end" float="left">
            <Text variant="register">Erynn Petersen, CEO, Emme</Text>
            <Text variant="register">
              Former CTO Kessel Run, US Dept. of Defense
            </Text>
          </VStack>
          <HStack minWidth="100%" justifyContent="space-around" mt="4vh">
            <Image width="8vw" src={EmmeLogo} alt="Emme Logo" />
            <Image width="7vw" src={CallBoxLogo} alt="Call Box Logo" />
            <Image width="8vw" src={LaconicLogo} alt="Laconic Logo" />
            <Image width="6vw" src={GdahaLogo} alt="Gdaha Logo" />
          </HStack>
        </VStack>
      </Message>
      <Footer />
    </AccountLayout>
  )
}
