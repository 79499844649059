import React from "react"

import { Text } from "@chakra-ui/react"

import RegisterConfirm from "@/components/RegisterConfirm"
import Message from "@/components/Message"
import Footer from "@/components/Footer"
import AccountLayout from "@/components/AccountLayout"

export default function RegisterConfirmView() {
  return (
    <AccountLayout>
      <RegisterConfirm />
      <Message>
        <Text fontSize="2em">Thank you signing up for Blind Insight!</Text>
        <Text fontSie="1.5em">
          Please check your email for an activation link!
        </Text>
      </Message>
      <Footer />
    </AccountLayout>
  )
}
