const Inputs = {
  baseStyle: {},
  variants: {
    standard: {
      field: {
        borderRadius: 100,
      },
      addon: {
        borderRadius: 100,
      },
      background: "white",
      borderRadius: "smallRadii",
    },
    standardAddon: {
      size: "sm",
      addon: {
        padding: "0.5em",
        borderRadius: "0.5em",
        bg: "Gray.200",
        fontWeight: "bold",
      },
      field: {
        bg: "Gray.200",
        padding: "lg",
        borderRadius: "lg",
      },
    },
    primary: {
      background: "white",
      borderRadius: "sm",
      borderColor: "Gray.200",
      field: {
        bg: "Gray.100",
      },
    },
    filter: {
      field: {
        maxHeight: "1.5em",
        padding: "0.25em",
        bg: "Gray.100",
        borderStyle: "none",
        borderRadius: "0.25em",
      },
    },
    account: {
      field: {
        color: "white",
        opacity: "95%",
        fontSize: "1.2em",
        borderRadius: "0",
        bg: "transparent",
        pt: "0.5vh",
        pb: "0.5vh",
        pl: "0em",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "0 0 1px 0",
        _placeholder: {
          color: "white",
          opacity: "75%",
        },
      },
    },
    accountPlaceholder: {
      field: {
        color: "white",
        opacity: "95%",
        fontSize: "1.2em",
        borderRadius: "0",
        bg: "transparent",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "0 0 1px 0",
      },
      addon: {
        bg: "transparent",
        borderRadius: "0",
        color: "white",
        fontSize: "1.2em",
        padding: "0.5em",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "0 0 1px 0",
      },
    },
  },
}

export default Inputs
